@import url("https://fonts.cdnfonts.com/css/mikado");

* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: "Mikado", sans-serif !important;
  overflow: hidden;
}
* {
  outline: none;
}

html {
  overflow-x: hidden;
}

a,
span {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

.landingpage {
  background-image: url("../Assets/imgs/lp-dsk-bg.webp");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.main-content {
  max-width: 410px;
  text-align: center;
  margin: auto;
  padding: 20px 0px 20px 0px;
  min-height: 100vh;
}
.main-img-wraper {
  position: relative;
}
.goldstar {
  position: absolute;
  bottom: -62px;
  left: -56px;
  max-width: 36%;
  z-index: 99;
}

.main-content .image {
  margin-top: 20px;
}

.main-content .image img {
  width: 100%;
  transform: scale(1.13);
  margin-top: 20px;
}

.main-content .form {
  position: absolute;
  bottom: 29px;
  /* min-height: 300px; */
  z-index: 9999;
  margin: auto;
  width: 92%;
  left: 4%;
}
.main-content .price-text {
  font-size: 12px;
  color: #d9d9d9;
  margin: 5px 0px;
}
.main-content .text {
  text-align: center;
  font-size: 19px;
  line-height: 21px;
  font-weight: 800;
  width: 100%;
  padding: 0 10px;
  color: #ffffff;
  -webkit-text-stroke: 0.3px #3c5511;
}

.main-content .code-number {
  display: flex;
  border: none;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.main-content input {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: transparent;
  padding: 14px 10px;
  border-radius: 12px;
  width: 100%;
  font-size: 18px;
  color: #fff;
  position: relative;
  z-index: 3;
}
.main-content input::placeholder {
  color: #aca3a9;
}

.main-content .input {
  width: 81%;
}

.main-content .fixed-cnt {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  padding: 13px 10px;
  background: transparent;
  /* font-weight: 600; */
  color: #fff;
  font-size: 18px;
}

.main-content button {
  margin: 10px 0;
  border: none;
  border-radius: 15px;
  display: inline-flex;
}
.main-content button:hover {
  cursor: pointer;
}

.main-content .explore-btn {
  display: inline-block;
  margin: 10px 0;
}
.main-content .explore-btn img {
  width: 90%;
}

.form-text p {
  color: #d9d9d9;
  text-align: center;
  font-size: 13px;
}
.condition a {
  color: #d9d9d9;
  font-size: 13px;
  text-decoration: none;
}

.condition a:hover {
  color: #ffc700;
}

.form .trop-image {
  position: absolute;
  right: -240px;
  bottom: -105px;
}

.form .trop-image img {
  width: 320px;
}

.form .lp-loader {
  background: #6E93FE;
  border: none;
  padding: 14px 8px;
  color: rgba(3, 3, 3, 1);
  border-radius: 38px;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  position: relative;
  width: 60%;
  margin: auto; 
}

/* otp */

.form.otp .otp-inputs {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 10px;
}

.form.otp .otp-inputs input {
  text-align: center;
  display: flex;
  gap: 10px;
  width: 52px;
  height: 52px;
  background: #1c4384;
  box-shadow: 0px 0px 0px 3px rgba(61, 112, 201, 0.4),
    inset 0px 3px 2px rgba(0, 0, 0, 0.25);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.form.otp .resend {
  font-size: 14px;
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
}

.form.otp .resend .resend-otp {
  color: #ffc700;
  margin-left: 5px;
  cursor: pointer;
}

.form.otp .resend .resend-otp:hover {
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

.form.otp .resend span:hover {
  color: #fff;
  cursor: pointer;
}
.form.otp .text {
  font-size: 22px;
  margin-bottom: 10px;
}
.form.otp .form-text p {
  margin-bottom: 10px;
  color: #fff;
}

.form p.invalid {
  font-size: 14px;
  margin: 14px;
  color: rgba(255, 180, 171, 1);
}

@media (max-width: 776px) {
  .landingpage {
  }
  .goldstar {
    bottom: -51px;
    left: -36px;
  }
  .main-content {
    padding-bottom: 10px;
    max-width: 100%;
  }

  .form .trop-image img {
    width: 200px;
  }
  .form-text p {
    font-size: 10px;
  }
}
